.feed-item-container {
  position: relative;
  width: 100%;

  // Note:
  // This defines the height of the slide. needs to be absolute numbers for some reason
  // This calculation needs to match exactly the calculation we are doing for #root element in App.scss
  height: calc(100svh + env(safe-area-inset-bottom) * 1.5);

  overflow: hidden;

  .feed-item-image {
    width: 100%;
    height: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
  }
}

:root.desktop {
  .feed-item-container {
    height: var(--desktop-game-height);

    &.full-screen {
      height: var(--desktop-game-height-full);
    }

    .feed-item-image {
      background-repeat: no-repeat;
    }
  }
}

:root.mobile {
  .feed-item-container {
    height: calc(100svh + env(safe-area-inset-bottom) * 1.5);
  }
}
