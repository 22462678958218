.desktop-sidebar {
  pointer-events: auto;
  z-index: 3;

  position: absolute;
  right: var(--padding-info-overlay-x);
  top: var(--padding-info-overlay-y);

  right: 0;
  top: 0;
  height: var(--desktop-sidebar-height);
  width: var(--desktop-sidebar-width);
  max-width: var(--desktop-sidebar-width);

  border-radius: 16px 0 0 16px;
  border: 1px solid var(--fill-color);
  box-shadow: var(--box-shadow-assistant);

  // solid bg
  background-color: var(--bg-color-body);
  color: var(--text-color);

  overflow: hidden;

  // --------------

  transition: opacity var(--animation-duration) ease-out;

  &.show-anim {
    animation: slide-in-right 0.25s ease-out forwards;
    pointer-events: auto;
  }

  &.hide-anim {
    animation: slide-out-right 0.25s ease-out forwards;
    pointer-events: none;
  }
}
