// ==========================================================
// Default / Mobile

.game-feed {
  width: 100%;
  height: 100svh;

  .carousel-container {
    height: 100%;

    .carousel-root {
      height: 100%;
    }
  }
}

// important: note that the carousel slider (FeedItem.scss)
// needs to be 100svh based absolute size for carousel to render properly
.carousel {
  li {
    list-style: none;
  }

  .control-arrow {
    display: none;
  }
}

.game-feed .bottom-gradient {
  display: block;
  pointer-events: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(var(--bottom-navbar-height) * 2 + 28px);
  z-index: 3;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
  // background-image: linear-gradient(transparent, var(--color-dark-50));
  // background-color: rgba(255, 0, 0, 0.5); // debug height
}

// ==========================================================
// Desktop

:root.desktop {
  .game-feed {
    width: 100%;
    height: calc(100svh - var(--desktop-navbar-height) - 0px);
  }

  .game-feed.with-sidebar {
    width: calc(100% - var(--desktop-sidebar-width) + 20px);

    .carousel.carousel-slider .control-arrow {
      right: calc(var(--padding-info-overlay-x) + 22px);
    }
  }

  .carousel {
    height: calc(100svh + var(--desktop-navbar-height));
  }

  // Note: 'height' style of the .slider-wrapper and .slider classes is automatically overriden by the Carousel internally using style={height}
  // We made a javascript hack in GameFeed.tsx to overcome it.
  // .carousel .slider-wrapper.axis-vertical {
  // border: 1px solid orange; // debug purposes

  // .slider {
  // todo: this works for separating slides, but then the scrolling gets screwed
  // todo: because is based on slide height and positions without accounting for the gap
  // display: flex;
  // flex-direction: column;
  // gap: 20px;
  // }
  // }

  // rendering a line where the game ends and we start showing additional slides,
  // just for visual debug purposes
  .debug-line {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 2px;
    top: var(--desktop-game-height);
    border-top: 1px solid var(--fill-color);
  }

  // display functional up and down arrows in desktop
  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;

    background-color: white;
    border-radius: 100px;

    right: calc(var(--padding-info-overlay-x) + 0px);

    background-color: black;
  }

  .carousel .control-prev.control-arrow {
    left: unset;
    top: var(--padding-info-overlay-y);
    bottom: unset;
  }

  .carousel .control-next.control-arrow {
    left: unset;
    top: calc(var(--desktop-game-height) - 40px - var(--padding-info-overlay-y));
    bottom: unset;
  }

  .carousel .control-prev.control-arrow::before {
    transform: rotate((90deg)) translate(-2px, 0px);
  }

  .carousel .control-next.control-arrow::before {
    transform: rotate((90deg)) translate(1px, 0px);
  }

  .game-feed .bottom-gradient {
    height: 260px;
  }
}

// ==========================================================
