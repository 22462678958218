$safe-top: calc(env(safe-area-inset-top) * 1);

.debug-component {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 13px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);

  pointer-events: none;
  z-index: 101;
  position: absolute;
  top: calc(var(--padding-info-overlay-y) + 55px);
  left: var(--padding-info-overlay-x);
  margin-top: $safe-top;

  border-radius: 10px;
  padding: 12px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 8px;

  // .debug-component-label {}

  .debug-component-button {
    pointer-events: auto;
  }
}
