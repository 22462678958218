// ===================================================

:root.desktop {
  .item-info {
    position: fixed;
    top: var(--desktop-navbar-height);
    height: var(--desktop-game-height);
    width: 100%;

    &.full-screen {
      height: var(--desktop-game-height-full);
    }

    // adjust sidebar vertical position for desktop
    .sidebar {
      top: calc(50% - 198px + 8px); // 396 / 2
    }

    .item-info-bottom {
      bottom: calc(var(--padding-info-overlay-y));
      .description {
        font-size: 32px;
        line-height: 40px;
      }
      .author {
        font-size: 16px;
      }
    }
  }

  .item-info.with-sidebar {
    width: calc(100% - var(--desktop-sidebar-width));
  }
}

:root.mobile {
  .item-info {
    // adjust sidebar vertical position for mobile
    // in mobile, sidebar needs to grow from the app bottom, not from the center
    .sidebar {
      // top: calc(50% - 198px - 20px); // 396 / 2
      top: calc(100% - var(--bottom-navbar-height) - 396px - 34px);
      right: calc(var(--padding-info-overlay-x) - 4px);
    }

    .item-info-bottom {
      bottom: calc(var(--padding-info-overlay-y) + var(--bottom-navbar-height) + env(safe-area-inset-bottom) * 0.5);
      .description {
        font-size: 24px;
        line-height: 30px;
      }
      .author {
        font-size: 12px;
      }
    }
  }
}

// ===================================================

.item-info {
  pointer-events: none;
  z-index: 4;

  width: 100%;
  max-width: var(--app-max-width);
  height: 100%;

  margin: 0 auto;

  position: fixed;
  top: 0;

  background: none;

  font-family: 'Pangram Medium', sans-serif;
  font-size: 25px;
  color: white;
  text-align: left;

  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: -25px;

  .item-info-bottom {
    position: absolute;
    bottom: calc(var(--padding-info-overlay-y) + var(--bottom-navbar-height) + env(safe-area-inset-bottom) * 0.5);
    left: calc(var(--padding-info-overlay-x) - 8px);
    height: fit-content;
    width: calc(100% - var(--padding-info-overlay-x) * 2);

    &.item-info-bottom-without-bottom-bar {
      bottom: calc(0px + 3px + env(safe-area-inset-bottom) * 0.5);
    }

    &.enabled {
      animation: slide-in-left 0.35s ease-out forwards;
    }

    &.disabled {
      animation: slide-out-left 0.35s ease-out forwards;
    }

    .gem {
      pointer-events: auto;
      // background-color: rgba(255, 0, 0, 0.5);
      width: 80px;
      height: 100px;
      margin-bottom: 20px;

      &.show-anim {
        animation: slide-in-left 0.25s ease-out forwards;
        pointer-events: auto;
      }

      &.hide-anim {
        animation: slide-out-left 0.25s ease-out forwards;
        pointer-events: none;
      }

      .bottombar-item-icons-container {
        position: relative;
        width: 76px;
        height: 76px;

        .bottombar-item-icon {
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 76px;
          height: 76px;
          margin: auto;
        }
      }

      .sidebar-item-label {
        font-family: 'Pangram Bold';
        font-size: 22px;

        user-select: none;
        height: fit-content;
        width: 100%;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        margin-top: 0px;

        display: flex;
        gap: 3px;
        align-items: center;
        justify-content: center;

        img {
          width: 20px;
          height: 20px;
        }
        &.button-ellipsis {
          padding: 3px;
          font-size: 11px;

          img {
            width: 17px;
            height: 17px;
          }
        }
      }
    }

    .author {
      pointer-events: auto;
      font-family: 'Pangram Medium';
      font-size: 16px;
      text-decoration: none;
      width: 70%;
      color: var(--text-color-white-50);
      margin-bottom: 2px;
      margin-left: 4px; // todo
    }

    .description {
      font-family: 'Pangram Bold';
      font-size: 32px;
      line-height: 40px;
      color: white;
      text-align: left;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      width: 100%;
      // height: 18px; // safari needs this to render just one line...

      height: fit-content;
      text-overflow: ellipsis;

      margin-left: 8px;

      // necessary
      p {
        margin: 0;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  .line-mid-screen {
    position: absolute;
    top: calc(50% - var(--bottom-navbar-height) / 2);
    background-color: red;
    width: calc(100% - 40px);
    height: 1px;
  }

  .sidebar {
    // background-color: rgba(255, 0, 0, 0.5);

    pointer-events: auto;
    position: absolute;
    right: calc(var(--padding-info-overlay-x) - 10px);
    top: calc(50% - 198px - 0px); // 396 / 2

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    width: 60px;

    .sidebar-item {
      background: none;
      font-family: 'Pangram Bold', sans-serif;
      font-size: 13px;
      text-decoration: none;
      text-align: center;
      color: white;

      display: flex;
      flex-direction: column;
      cursor: pointer;

      align-items: center;

      // background-color: orange;

      .sidebar-item-icons-container {
        position: relative;
        width: 48px;
        height: 48px;

        .sidebar-item-icon {
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 48px;
          height: 48px;
          margin: auto;
        }
      }

      .sidebar-item-label {
        user-select: none;
        height: fit-content;
        margin-top: 0;
        width: 100%;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
      }

      &.author {
        padding-bottom: 12px;

        .sidebar-item-icons-container {
          width: 48px;
          height: 48px;
          .sidebar-item-icon {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
            background-color: black;
          }
        }

        .author-icon-plus {
          position: absolute;
          left: calc(50% - 12px);
          top: calc(100% - 12px);
          width: 24px;
          height: 24px;
          border-radius: 50%;
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

          background-color: var(--navbar-color);
          -webkit-backdrop-filter: blur(var(--blur-amount));
          backdrop-filter: blur(var(--blur-amount));

          background-position: center;
          background-size: 65%;
          background-repeat: no-repeat;
          border: none;
        }
      }

      &.gem {
        margin-top: 0;
        .sidebar-item-icons-container {
          padding-bottom: 6px;
          width: 56px;
          height: 48px;
          .sidebar-item-icon {
            width: 56px;
            height: 56px;
          }
        }
        .sidebar-item-label {
          margin-top: 7px;
          display: flex;
          gap: 3px;
          align-items: center;
          justify-content: center;

          img {
            width: 20px;
            height: 20px;
          }
          &.button-ellipsis {
            padding: 3px;
            font-size: 11px;

            img {
              width: 17px;
              height: 17px;
            }
          }
        }
      }

      &.like {
        margin-top: 4px;
        .sidebar-item-icons-container {
          width: 35px * 0.85;
          height: 35px * 0.85;
          padding-bottom: 6px;
          .sidebar-item-icon {
            width: 35px * 0.85;
            height: 35px * 0.85;

            filter: var(--svg-color-light) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
          }
        }
        .sidebar-item-label {
          margin-top: 0;
        }

        &.liked {
          .sidebar-item-icon {
            filter: var(--svg-color-red) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
          }
        }
      }

      &.comment {
        margin-top: 0;
        .sidebar-item-icons-container {
          width: 35px * 0.85;
          height: 35px * 0.85;
          padding-bottom: 6px;
          .sidebar-item-icon {
            width: 35px * 0.85;
            height: 35px * 0.85;
            filter: var(--svg-color-light) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
          }
        }
        .sidebar-item-label {
          margin-top: 0;
        }
      }

      &.share {
        margin-top: -4px;
        .sidebar-item-icons-container {
          width: 48px * 0.85;
          height: 48px * 0.85;
          padding-bottom: 2px;
          .sidebar-item-icon {
            width: 48px * 0.85;
            height: 48px * 0.85;
            filter: var(--svg-color-light) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
          }
        }
        .sidebar-item-label {
          margin-top: 0;
        }
      }

      // ------------ debug elements -------------

      &.gem.sidebar-item-debugGemName {
        position: absolute;
        user-select: none;
        height: fit-content;

        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
        right: 56px;
        top: 16px;
        text-align: right;
        width: 300px;
      }

      &.debug {
        pointer-events: none;
        width: 300px;
        margin-right: 250px;
        font-size: 13px;
        line-height: 20px;
        color: red;
        text-align: right;
      }
    }
  }
}
