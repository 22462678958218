.backdrop-comments-tab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: background-color 0.25s ease-out;
  background-color: rgba(0, 0, 0, 0);
  z-index: 0;

  &.show {
    pointer-events: auto;
    background-color: var(--color-dark-50);
    z-index: 5;
  }
}

.list-layout.game-tabbed-list-layout {
  flex-grow: 1;
  position: relative;
  z-index: 1;

  .list-layout-list {
    cursor: auto;
  }

  .list-layout-item {
    z-index: 0;

    .title {
      margin-bottom: 0;
    }
  }

  .status {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
  }

  .follow-btn {
    padding: 8px 16px;
    font-size: 13px;
  }

  .game-comment {
    .title {
      // duplicated here to overcome specificity
      padding-top: 0;
      font-family: 'Pangram Bold';
      font-size: 13px;
      color: var(--text-color-50);
    }
    .list-layout-item-wrapper {
      border-style: none;
    }
    .list-layout-item-images {
      align-items: start;
      width: 60px;
      height: 40px;
      .round-picture {
        height: 40px;
      }
    }
    .list-layout-item-info {
      justify-content: start;
    }
    &.reply {
      .list-layout-item-images {
        width: 33px;
        height: 20px;
        .round-picture {
          height: 20px;
        }
      }
    }
  }
}
