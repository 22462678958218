// .backdrop-comment-sidebar {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: calc(100% - var(--desktop-sidebar-width));
//   height: 100%;
//   pointer-events: none;
//   z-index: 5;

//   transition: background-color 0.25s ease-out;
//   &.show {
//     pointer-events: auto;
//     background-color: var(--color-dark-50);
//   }
// }

.comment-sidebar {
  display: flex;
  flex-direction: column;
  z-index: 4;

  .button-close {
    cursor: pointer;
    z-index: 999;
    position: absolute;
    top: 12px;
    right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    // border: 1px solid var(--text-color-50);

    padding: 5px;
    box-sizing: border-box;

    .icon-close {
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      filter: var(--svg-color);
    }
  }
}
