.game-comment {
  padding-top: 16px !important;

  .title {
    padding-top: 0;
    font-family: 'Pangram Bold';
    font-size: 13px;
    color: var(--text-color-50);
  }
  .footer {
    padding-top: 10px;
    font-size: 12px;
    color: var(--text-color-50);
    display: flex;
    gap: 20px;

    .timestamp {
      font-family: 'Pangram Medium';
    }
    .reply-link {
      font-family: 'Pangram Bold';
    }
  }

  .rel-dot {
    padding: 0 6px;
  }

  .rel-tag {
    &.creator-tag {
      color: var(--text-color);
      // color: var(--color-gold);
    }
    &.friend-tag {
      color: var(--color-pink);
    }
  }

  .reply-triangle {
    font-size: 8px;
    margin: 0 6px;
  }

  .more-section {
    font-family: 'Pangram Bold';
    font-size: 13px;
    color: var(--text-color-50);
    padding-top: 10px;
    display: flex;
    justify-content: start;
    max-width: 600px;
    gap: 20px;
  }
  .more-link {
    text-align: left;
  }
  .hide-replies-link {
    text-align: left;
  }

  &.reply {
    padding: 0 0;
  }
  &.ephemeral {
    opacity: 0.5;
  }
}

.comment-input {
  // background-color: var(--bg-color);
  // border-top: 1px solid var(--bg-color);

  display: flex;
  flex-direction: row;
  gap: 8px;

  pointer-events: auto;
  width: calc(100% - 24px);
  padding: 12px 12px;

  max-height: 160px; // limit auto-expand to 8 rows

  position: relative;
  z-index: 10;

  .comment-input-form {
    // background-color: rgba(255, 0, 0, 0.5);
    background-color: var(--fill-color);
    position: relative;
    width: calc(100% - 40px);
    border-radius: 25px;
  }

  .comment-input-textarea {
    // background-color: rgba(0, 0, 0, 0.1);
    background-color: unset;
    border-radius: 25px;
    border: none;

    font-family: 'Pangram Medium';
    font-size: 16px;
    color: var(--text-color);

    padding: 16px 16px 12px 16px;
    width: calc(100% - 40px);

    resize: none;
    // overflow: hidden;

    max-height: calc(160px - 16px); // limit auto-expand to 8 rows

    // -webkit-backdrop-filter: blur(var(--blur-amount));
    // backdrop-filter: blur(var(--blur-amount));

    &:focus {
      border: none;
      outline: none;
    }
  }

  .comment-input-send-button {
    // background-color: rgba(255, 0, 0, 0.5);

    width: 30px * 3;
    height: 30px * 3;
    margin: auto;

    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0; // calc(0px + env(safe-area-inset-bottom) * 0.5);

    .comment-input-send-icon {
      cursor: pointer;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50%;
      width: 25px * 1;
      height: 25px * 1;
      margin-right: 5px;
      margin-top: 5px;

      margin-left: 50px;
      margin-top: 41px;

      // filter: var(--svg-color-gold) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
      filter: var(--svg-color); //  drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
      opacity: 0.7;
    }
  }
}
